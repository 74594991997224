<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
  import { VueOfflineMixin } from 'vue-offline'

  const default_layout = 'admin'

  export default {
    name: 'App',

    mixins: [VueOfflineMixin],

    computed: {
      layout() {
        return 'layout-' + ( this.$route.meta.layout || default_layout )
      },
    },

    mounted() {
      if ( this.isOffline ) {
        this.$toasted.show( 'Отсутствует Интернет-соединение.', {
          type: 'error',
          theme: 'toasted-primary',
          duration: null,
          action: {
            text: 'Закрыть',
            onClick: ( e, toastObject ) => {
              toastObject.goAway( 0 )
            },
          },
        } )
      }

      this.$on( 'offline', () => {
        this.$toasted.show( 'Отсутствует Интернет-соединение.', {
          type: 'error',
          theme: 'toasted-primary',
        } )
      } )

      this.$on( 'online', () => {
        this.$toasted.show( 'Теперь Вы Online!', {
          type: 'success',
          theme: 'toasted-primary',
        } )
      } )
    },
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
  @import 'assets/scss/app';
  @import 'assets/scss/bootstrap';
</style>
